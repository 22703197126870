export const dataPortfolio = [
    {
        id: 0,
        name: "Lost Riders Motorcycle Club Rat City Original",
        href: "https://opensea.io/collection/lost-riders-motorcycle-club-rat-city-original",
        opensea: "https://opensea.io/collection/lost-riders-motorcycle-club-rat-city-original",
        image: 'logoRRC.png',
        chain: "ETHEREUM MAINNET",
        startPrice: "0.26 ETH",
        maxSupply: "7777",
        hrefPage: "https://rottenratcity.io/",
        desc: "The Lost Riders is a collection of 7777 one-of-a-kind hand-drawn art NFTs by renowned artist BUSTER WISE, whose work has appeared in magazines and blogs such as 13 and a Half, Cycles Sources, dWrenched, Chop, Ride & Party, and others. The Lost Riders NFTs are designed for those who enjoy freedom and action, and that the world is for tough people who live by rules.",
    },
    {
        id: 1,
        name: "COMING SOON",
        href: "/Portfolio",
        opensea: "-",
        image: "fabric.png",
        chain: "POLYGON",
        startPrice: "MATIC",
        maxSupply: "-",
        hrefPage: "-",
        desc: "-",
    }
]