import React from 'react'
import HeaderPortfolio from '../components/Header/HeaderPortfolio'
import PortfolioContent from '../components/Portfolio/PortfolioContent'
import PortfolioTitle from '../components/Portfolio/PortfolioTitle'

const Portfolio = () => {

  return (
    <div>
      <HeaderPortfolio />
      <div className="flex justify-center">
      <PortfolioTitle />
      </div>
      <div className="mt-10">
        <PortfolioContent />
      </div>
      <div className="h-40"></div>
    </div>
  )
}

export default Portfolio