import React from 'react'
import { NewspaperIcon, TerminalIcon, ChipIcon, ViewListIcon, ShieldCheckIcon, SupportIcon } from "@heroicons/react/outline"

const TokenContent = () => {
    return (
            
        <section className="text-black" id="utilityCoin">
            <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
                <div className="max-w-xl mx-auto text-center">
                    <h2 className="md:text-7xl font-bold text-5xl bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">UTILITY COIN </h2>

                    <p className="mt-4 text-black text-3xl">
                        Create a token that, in combination with economic models, will create a gateway to a Web3-based financial system
                    </p>
                </div>

                <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3">
                    <div
                        className="block p-8 transition border border-purple-500 shadow-xl rounded-xl hover:shadow-purple-500 hover:border-indigo-500"
                       
                    >
                        <NewspaperIcon className="h-10 w-10 text-black" />

                        <h3 className="mt-4 text-4xl font-bold text-black">WHITEPAPER</h3>

                        <p className="mt-1 text-2xl text-black">
                            We create economic plans, tokenomy and economic models appropriately matched to the individual needs of the project
                        </p>
                    </div>
                    <div
                        className="block p-8 transition border border-purple-500 shadow-xl rounded-xl hover:shadow-purple-500 hover:border-indigo-500"
                    >
                        <TerminalIcon className="h-10 w-10" />

                        <h3 className="mt-4 text-4xl font-bold text-black">DEVELOPING</h3>

                        <p className="mt-1 text-2xl text-black">
                            We code smart contracts and fronted web3
                        </p>
                    </div>

                    <div
                        className="block p-8 transition border border-purple-500 shadow-xl rounded-xl hover:shadow-purple-500 hover:border-indigo-500"
                    >
                        <ChipIcon className="h-10 w-10" />

                        <h3 className="mt-4 text-4xl font-bold text-black">PRODUCTION</h3>

                        <p className="mt-1 text-2xl text-black">
                            We deploy Your project on the selected blockchain and train You in managing smart contracts
                        </p>
                    </div>

                    <div
                        className="block p-8 transition border border-purple-500 shadow-xl rounded-xl hover:shadow-purple-500 hover:border-indigo-500"
                    >
                        <ViewListIcon className="h-10 w-10" />

                        <h3 className="mt-4 text-4xl font-bold text-black">LISTINGS</h3>

                        <p className="mt-1 text-2xl text-black">
                            We will list your project on most exchanges - centralized and decentralized
                        </p>
                    </div>

                    <div
                        className="block p-8 transition border border-purple-500 shadow-xl rounded-xl hover:shadow-purple-500 hover:border-indigo-500"
                    >
                        <ShieldCheckIcon className="w-10 h-10" />

                        <h3 className="mt-4 text-4xl font-bold text-black">AUDIT</h3>

                        <p className="mt-1 text-2xl text-black">
                            We will carry out an audit of smart contracts with the help of a special tool. You will receive the test results within 2 business days
                        </p>
                    </div>

                    <div
                        className="block p-8 transition border border-purple-500 shadow-xl rounded-xl hover:shadow-purple-500 hover:border-indigo-500"
                    >
                       <SupportIcon className="h-10 w-10" />
                        <h3 className="mt-4 text-4xl font-bold text-black">SUPPORTS</h3>

                        <p className="mt-1 text-2xl text-black">
                            We provide support in the field of project maintenance, smart contracts and web3 network infrastructure
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenContent