import React from 'react'
import bg from "../../../image/home4.jpg"

const Metaverse = () => {
    return (
        <div className="relative w-full flex flex-col-reverse md:flex-row items-center overflow-hidden" id="metaverse">

      {/* :HERO IMAGE */}
      <div className="absolute w-full h-full">
        <img src={bg} alt="" className="absolute w-full h-full object-cover" />
      </div>

      {/* :PRODUCT */}
      <div className="hidden sm:block relative w-full md:w-1/2 h-1/2 md:h-full">
      </div>

      {/* :HERO MAIN */}
      <div className="relative pt-20 pb-10 md:py-40 px-3 w-full md:w-1/2 h-full sm:h-1/2 md:h-full flex flex-col justify-center items-center md:items-start text-center md:text-left text-white">

        {/* ::Small Title */}
        <h2 className="text-3xl font-semibold bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">Create</h2>

        {/* ::Big Title */}
        <h1 className="text-5xl sm:text-7xl font-semibold bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">P2E & META</h1>
        <div className="mt-20 lg:mt-0"></div>
        {/* ::Text */}
        <p className="max-w-lg py-5 text-2xl leading-relaxed text-black font-bold">Create a game for the crypto community. Choose a crypto economic model. Set the rules of the game and enter the beautiful world of Play to Earn and Metaverse</p>

      </div>
      
    </div>

    )
}

export default Metaverse