import './App.css';
import Banner from './components/Banner/Banner';
import Contact from './components/Contact/Contact';
import Content from './components/Content/Content';
import Defi from './components/Content/DeFi/Defi';
import Launchpad from './components/Content/DeFi/Launchpad';
import Metaverse from './components/Content/Metaverse/Metaverse';
import Nft from './components/Content/Nft/Nft';
import NftCollection from './components/Content/Nft/NftCollection';
import TokenContent from './components/Content/UtilityToken/TokenContent';
import UtilityCoin from './components/Content/UtilityToken/UtilityCoin';
import Feature from './components/Feature/Feature';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Mission from './components/Mission/Mission';
import ScrollUpButton from "react-scroll-up-button";
import MetaverseContent from './components/Content/Metaverse/MetaverseContent';
import MetaTags from 'react-meta-tags';


function App() {
  return (
    <div>
      <MetaTags>
        <title>NFT FABRIC</title>
        <meta id="nft fabric" name="NFT Fabric" content="Web3 software house" />
        <meta id="og-title" property='og:title' content="NFT FABRIC" />
        <meta id="og-image" property='og:image' content="./image/logo.jpg" />
        <meta property='og:description' content="Nft Defi Gamefi Metaverse" />
        
      </MetaTags>
      <Header />
      <Main />
      <Mission />
      <div className="flex justify-center mb-10 lg:mb-40 mt-10 lg:mt-0">
        <Banner />
      </div>
      <Feature />
      <div className="flex justify-center mt-10 lg:mt-40 ">
        <Content />
      </div>
      <div className="flex justify-center mb-10 lg:mt-40">
        <Nft />
      </div>
      <NftCollection />
      <div className="mt-10 lg:mt-20">
        <TokenContent />
      </div>
      <div className="mt-10 lg:mt-20 mb-10 lg:mb-20 flex justify-center">
        <UtilityCoin />
      </div>
      <Defi />
      <Launchpad />
      <div className="mt-10 lg:mt-20 mb-10 lg:mb-20" >
        <Metaverse />
        <MetaverseContent />
      </div>
      <div className="flex justify-center mt-10 lg:mt-40">
        <Contact />
      </div>
      <Footer />
      <ScrollUpButton />
    </div>
  );
}

export default App;
