import React, { useEffect } from 'react'

const PortfolioTitle = () => {

    useEffect(() => {
        let styleOne = document.createElement("style")
        let styleTwo = document.createElement("style")
        let afterEffect = document.getElementById("after-te1")
        let before = document.getElementById("before-te1")
    
        const setKeyframesRules = (n, start = 0) => {
            let steps = ""
    
            for (let i = start; i <= n; i++) {
                let percent = (i / n) * 100
                let random1 = `${Math.random() * 150}px`
                let random2 = `${Math.random() * 150}px`
                steps = steps.concat(`${percent}% { clip: rect(${random1}, 9999px, ${random2}, 0) } `)
            }
            return steps
        }
    
        let keyframes1 = `@keyframes glitch-anim-1 { ${setKeyframesRules(24)} }`
        let keyframes2 = `@keyframes glitch-anim-2 { ${setKeyframesRules(32, 2)} }`
        styleOne.innerHTML = keyframes1
        styleTwo.innerHTML = keyframes2
        afterEffect.appendChild(styleOne)
        before.appendChild(styleTwo)
        afterEffect.style.animation = "glitch-anim-1 2.5s infinite linear alternate-reverse"
        before.style.animation = "glitch-anim-2 3s infinite linear alternate-reverse"
    
    }, [])


    return (
        <div className="lg:w-8/12 text-black  ">
            <div className="max-w-screen px-4 py-2 mx-auto lg:h-60 lg:flex mt-10 lg:mt-20 border-2 border-black shadow-indigo-600 shadow-lg">
                <div className="max-w-3xl mx-auto text-center">
                    <h1 className="text-5xl lg:text-7xl font-semibold bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">This is our portfolio</h1>
                    <div className="text-center mt-10 lg:mt-20">
                        <h1 className="text-black text-3xl lg:text-4xl font-bold uppercase relative inline-block">
                            <span id="before-te1" className="absolute top-0 left-0.5 w-full h-full bg-transparent" style={{ textShadow: "-4 px 0 #8c24d1", clipPath: "rect(24px, 550px, 90px, 0)" }} aria-hidden="true">Discover the projects made by us</span> {/* glitch::before */}
                            Discover the projects made by us
                            <span id="after-te1" className="absolute top-0 -left-0.5 w-full h-full bg-transparent" style={{ textShadow: "-4px 0 spin(#8c24d1, 180)", clipPath: "rect(85px, 550px, 140px, 0)" }} aria-hidden="true">Discover the projects made by us</span> {/* glitch::after */}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioTitle