export const dataCard = [
    {
        id: "1",
        title: "NFT",
        desc: "Create an amazing NFT collection",
        href: "/#nft"
    },
    {
        id: "2",
        title: "Utility coin",
        desc: "List your coin on the crypto exchange",
        href: "/#utilityCoin"
    },
    {
        id: "3",
        title: "DeFi",
        desc: "Build a decentralized financial system",
        href: "/#defi"
    },
    {
        id: "4",
        title: "Play to Earn and Metaverse",
        desc: "Create a virtual world with real economy",
        href: "/#metaverse"
    },
]