import React from 'react'

const ContentCard = ({ product }) => {
    return (
        <section
            className="bg-gradient-to-r from-indigo-500 via-purple-500 to-indigo-500 flex justify-center items-center gap-x-16 text-white border-2 border-black" 
        >
            <div
                className="w-[300px] h-[420px] bg-transparent cursor-pointer group perspective"
            >
                <div
                    className="relative preserve-3d group-hover:my-rotate-y-180 w-full h-full duration-1000"
                >

                    <div className="absolute backface-hidden border-2 w-full h-full">
                    </div>
                    <div
                        className="absolute my-rotate-y-180 backface-hidden w-full h-full bg-cardBg overflow-hidden"
                    >
                        <div
                            className="text-center flex flex-col items-center justify-center h-full text-white px-2 pb-24"
                        >
                            <h1 className="text-4xl lg:text-5xl font-semibold">{product.title}</h1>
                            <p className="text-3xl lg:text-2xl mt-10">
                                {product.desc}
                            </p>
                            <button
                                className="bg-purple-500 px-6 py-2 font-semibold text-white rounded-full absolute -bottom-20 delay-500 duration-1000 group-hover:bottom-20 scale-0 group-hover:scale-125"
                            >
                               <a href={product.href}> Watch Now </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentCard