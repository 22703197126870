import React from 'react'
import logo from "../../image/fabric.png"

const Main = () => {
  return (
    <section className="bg-transparent mt-10 mx-2 lg:mx-0">
    <div className="container flex flex-col items-center px-2 py-12 mx-auto xl:flex-row">
        <div className="flex justify-center xl:w-1/2">
                <img className="h-40 w-40 sm:w-[28rem] sm:h-[28rem] flex-shrink-0 object-cover rounded-lg" src={logo} alt="nft fabric"/>
        </div>

        <div className="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
            <div className="text-5xl md:text-7xl font-semibold bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">
                NFT FABRIC
            </div>

            <p className="block max-w-1/2 mt-4 text-2xl md:text-4xl text-black border-y-2 border-black">NFT & UTILITY COIN & METAVERSE & DEFI & PLAY TO EARN</p>
            <p className="block max-w-1/2 mt-4 text-2xl md:text-4xl text-black border-y-2 border-black">ONCHAIN DATA RAPORTS & WHITEPAPERS</p>
        </div>
    </div>
  </section>
  )
}

export default Main