import React from 'react'
import logo from "../../image/home5.jpg"

const Mission = () => {
    return (
        <section className=" lg:py-60 lg:flex lg:justify-center" id="mission">
            <div className="bg-white lg:mx-8 lg:flex lg:max-w-7xl lg:shadow-xl lg:rounded-lg lg:shadow-purple-600 ring-2 ring-black">
                <div className="lg:w-1/2">
                    <div className="bg-cover lg:rounded-lg h-full w-full">
                        <img className="w-full h-full" src={logo} alt="nft fabric mission" />
                    </div>
                </div>

                <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2">
                    <h2 className="text-5xl lg:text-7xl font-bold text-purple-600 animate-pulse">MISSION</h2>
                    <p className="mt-4 text-black text-2xl md:text-3xl lg:text-2xl font-medium">We are technology company that produces decentralized Web3 applications. Our mission is to increase the adoption of critical currencies
                        among companies. The adoption of cryptocurrencies allows the introduction of an innovative payment system based on blockchain networks. The scope of our mission
                        includes all kinds of decentralized apps, NFT collections, Play to Earn games, and the Metaverse world.</p>
                </div>
            </div>
        </section>
    )
}

export default Mission