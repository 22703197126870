import React from 'react'

const Banner = () => {
  return (
    <div className="w-6/12 bg-black ">
                <div className="text-white text-4xl text-center font-bold uppercase flex flex-col justify-around">
                    <span>Make</span>
                    <div className="overflow-hidden h-14">
                        <div className="animate-slide"><span className="inline-block text-white py-1 px-3 mt-1 mb-11 bg-purple-500">NFT</span></div>
                        <div><span className="inline-block text-white py-1 px-3 mb-11 bg-green-500">TOKEN</span></div>
                        <div><span className="inline-block text-white py-1 px-3 mb-11 bg-red-500">DEFI</span></div>
                    </div>
                    <span>Amazing Projects!</span>
                </div>
        </div>
  )
}

export default Banner