import React, { Suspense } from 'react'
import Coin from "./Coin.js"
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'


const UtilityCoin = () => {


  return (
    <div className="h-60 w-full md:h-96 md:w-11/12 lg:h-screen lg:w-10/12 border-4 border-black rounded-lg bg-gradient-to-r from-gray-500 to-gray-800">
      <Canvas className="w-full">
        <OrbitControls enableZoom={true} />
        <ambientLight intensity={10} />
        <directionalLight position={[-2,4,6]} intensity={10} />
        <Suspense fallback={null}>
          <Coin className="h-90 w-90" />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default UtilityCoin