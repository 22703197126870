/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: sebipaps (https://sketchfab.com/sebipaps)
license: SKETCHFAB Editorial (https://sketchfab.com/licenses)
source: https://sketchfab.com/3d-models/bitcoin-gold-coin-model-14c192503a004af081e57ba5fa39f1df
title: Bitcoin Gold Coin Model
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/coin.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.09, 0.04, 0.05]} rotation={[Math.PI, -1.55, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[-0.06, 0.12, 0.06]} rotation={[-Math.PI, 0, -Math.PI]} scale={1112.35}>
            <mesh geometry={nodes.Object_4.geometry} material={materials.metalTop} />
            <mesh geometry={nodes.Object_5.geometry} material={materials.metalTop} />
            <mesh geometry={nodes.Object_6.geometry} material={materials.metalBottom} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/coin.gltf')
