import React from 'react'


const NftCollection = () => {


  const nfts = [
    { id: 1, href: "#link", name: "NFT FABRIC", author: "Author", authorImg: require("../../../image/logo.jpg"), bannerImg: require("../../../image/home.jpg"), currentBid: "0.11 ETH", timeLeft: "Total: 10 FAB" },
    { id: 2, href: "#link", name: "YOUR NFT", author: "Author", authorImg: require("../../../image/logo.jpg"), bannerImg: require("../../../image/home.jpg"), currentBid: "6.1 SOL", timeLeft: "Total: 500 YOUR" },
    { id: 3, href: "#link", name: "CHECK US", author: "Author", authorImg: require("../../../image/logo.jpg"), bannerImg: require("../../../image/home.jpg"), currentBid: "777 MATIC", timeLeft: "Total: 1000 CUS" },
  ]


  return (
    <div className="relative mx-auto py-8 md:py-16 px-4 w-full max-w-7xl bg-gradient-to-b from-sky-800 to-gray-800">
      <div className="mx-auto max-w-5xl">

        {/* :TITLE CONTAINER */}
        <div className="flex items-end justify-between">
          <h2 className="text-4xl lg:text-5xl bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text font-bold">YOUR NFT COLLECTION</h2>
          <a href="#contact" className="text-xl text-white text-opacity-60 font-semibold hover:underline hover:text-opacity-80">CONTACT</a>
        </div>


        {/* :NFTs */}
        <div className="mt-8 mx-auto md:mx-auto max-w-md md:max-w-none grid grid-cols-1 md:grid-cols-3 gap-5 lg:gap-10">
          {nfts.map(nft => (
            <a href={nft.href} key={nft.id} className="col-span-1 group relative p-2 pb-5 flex flex-col items-center border-2 border-transparent rounded-xl bg-blue-300 bg-opacity-30 backdrop-filter backdrop-blur-sm hover:shadow-xl hover:border-purple-500">
              {/* ::Image */}
              <div className="relative max-h-64 rounded-2xl overflow-hidden">
                <img src={nft.bannerImg} alt="" className="object-cover transition duration-200 ease-in transform group-hover:scale-105" />
                {/* :::time left */}
                <span className="absolute top-2 right-2 py-1 px-3 rounded-full bg-black bg-opacity-30 text-md text-white font-bold backdrop-filter backdrop-blur-sm">{nft.timeLeft}</span>
              </div>
              {/* ::Details */}
              <div className="mt-2 px-2 w-full flex flex-col space-y-1">
                {/* :::name */}
                <p className="text-2xl text-white font-semibold tracking-wide group-hover:text-opacity-100">{nft.name}</p>
                {/* :::author */}
                <p className="flex items-center">
                  <span className="relative mr-2 w-7 h-7 rounded-full shadow-sm overflow-hidden" aria-label="avatar">
                    <img src={nft.authorImg} alt="" className="w-full h-full object-cover" />
                  </span>
                  <span className="text-md text-white font-bold tracking-wide">{nft.author}</span>
                </p>
                {/* :::bid infos */}
                <span className="flex justify-between items-center">
                  <span className="mr-4 text-md text-white font-semibold">{`Current Bid: ${nft.currentBid}`}</span>
                  <button type="button" className="relative inline-flex items-center px-2.5 py-1.5 rounded-full bg-gradient-to-t from-indigo-600 to-purple-200 text-md text-white font-bold tracking-wide hover:to-purple-500">Place Bid</button>
                </span>
              </div>
            </a>
          ))
          }
        </div>

      </div>
    </div>
  )
}

export default NftCollection