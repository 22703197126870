import React from 'react'
import { BsNewspaper } from "react-icons/bs"
import { GiFireShield } from "react-icons/gi"
import { FaFileContract } from "react-icons/fa"
import { AiOutlineShop } from "react-icons/ai"
import image from "../../../image/cardBg.jpg"

const MetaverseContent = () => {
    return (
        <section className="bg-gradient-to-r from-black via-purple-500 to-black">
            <div className="container px-6 py-10 mx-auto">
                <h1 className="text-5xl font-semibold text-white lg:bg-gradient-to-r lg:bg-clip-text lg:text-transparent from-indigo-500 via-purple-500 to-indigo-400">Explore our <br></br> Play to Earn content</h1>

                <div className="mt-2">
                    <span className="inline-block w-40 h-1 rounded-full bg-blue-500"></span>
                    <span className="inline-block w-3 h-1 ml-1 rounded-full bg-blue-500"></span>
                    <span className="inline-block w-1 h-1 ml-1 rounded-full bg-blue-500"></span>
                </div>

                <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
                    <div className="w-full lg:w-1/2 grid grid-cols-1 gap-8 xl:gap-16 md:grid-cols-2">
                        <div className="space-y-3">
                            <span className="inline-block p-3 text-white bg-purple-700 rounded-xl">
                                <BsNewspaper className="h-6 w-6" />
                            </span>

                            <h1 className="text-3xl lg:text-4xl font-semibold lg:bg-gradient-to-r lg:bg-clip-text text-white lg:text-transparent from-indigo-500 via-purple-500 to-indigo-400">Whitepaper</h1>

                            <p className="text-white text-xl">
                                Our experts will create a special whitepaper for You that is required to complete the Play to Earn game
                            </p>
                        </div>

                        <div className="space-y-3">
                            <span className="inline-block p-3 text-white bg-red-700 rounded-xl">
                                <GiFireShield className="w-6 h-6" />
                            </span>

                            <h1 className="text-3xl lg:text-4xl font-semibold text-white">Economic model</h1>

                            <p className="text-white text-xl">
                                We will create for you a dedicated economic model on which the game economy will be based
                            </p>
                        </div>

                        <div className="space-y-3">
                            <span className="inline-block p-3 bg-purple-700 text-white rounded-xl">
                                <FaFileContract className="w-6 h-6" />
                            </span>

                            <h1 className="text-3xl lg:text-4xl font-semibold lg:bg-gradient-to-r lg:bg-clip-text text-white lg:text-transparent from-indigo-500 via-purple-500 to-indigo-400">Smart Contracts</h1>

                            <p className="text-white text-xl">
                                We will prepare smart contracts for you, which will be controlled by financial flows in the game
                            </p>
                        </div>

                        <div className="space-y-3">
                            <span className="inline-block p-3 text-white bg-red-700 rounded-xl">
                                <AiOutlineShop className="w-6  h-6" />
                            </span>

                            <h1 className="text-3xl lg:text-4xl font-semibold bg-gradient-to-r bg-clip-text text-white ">Meta marketplaces</h1>

                            <p className="text-white text-xl">
                            We will create special decentralized shopping centers, auction houses, forms of exchange in which the player will trade their game items, event items, nft tokens
                            </p>
                        </div>
                    </div>

                    <div className="hidden lg:flex lg:w-1/2 lg:justify-center">
                        <img className="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={image} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MetaverseContent