import React from 'react'

const Nft = () => {
  return (
    <div className="lg:w-9/12" id="nft">
      <h1 className="text-5xl lg:text-7xl text-purple-500 text-center uppercase mb-10 font-semibold">Non-fungible token</h1>
      <div className="block overflow-hidden rounded-lg border-2 border-purple-500">
        <img className="object-cover w-full h-full lg:h-full" src={require("../../../image/home2.jpg")} alt="nft fabric" />

        <div className="p-4 bg-black">
          <p className="text-4xl lg:text-5xl bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">CREATE YOUT NFT</p>
          <p className="mt-10 text-xl md:text-2xl lg:text-2xl text-white">
            The tokenization of works of art, graphics, animations, digital goods and all collectors' works is progressing from year to year.
            If you would like to publish your NFT collection, we can help You. <br></br><br></br> We deal with the professional construction of the NFT collection.
            We will build for
            You NFT tokens, which will be managed by Smart Contract and you will start selling on a special basis
            dictated marketplaces such as Opensea.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Nft