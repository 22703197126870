import React from 'react'
import logo from "../../image/logo.jpg"
import { BsTelegram } from "react-icons/bs"
import { BsDiscord } from "react-icons/bs"
import {BsTwitter } from "react-icons/bs"
import {BsLinkedin } from "react-icons/bs"


const Footer = () => {
    return (
        <footer className="p-4 bg-black ronded-lg shadow md:px-6 md:py-8">
            <div className="sm:flex sm:items-center sm:justify-between">
                <a href="/" className="flex items-center mb-4 sm:mb-0">
                    <img src={logo} className="mr-4 h-8" alt="nft fabric" />
                    <span className="self-center text-2xl font-semibold whitespace-nowrap text-purple-400">NFT FABRIC</span>
                </a>
                <ul className="flex flex-wrap items-center mb-6 text-sm text-black sm:mb-0">
                    <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                        <a href="https://t.me/nftfabric" className="text-white hover:text-blue-300" target="_blank" rel="noopener noreferrer">
                           <BsTelegram className="h-8 w-8" />
                        </a>
                        <a href="https://twitter.com/PatrykSer" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-300">
                            <BsTwitter className="h-8 w-8" />
                        </a>
                        <a href="http://www.linkedin.com/in/nftfabric" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-300">
                            <BsLinkedin className="h-8 w-8 " />
                        </a>
                        <a href="https://discord.gg/NsEykmws" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-300">
                            <BsDiscord className="h-8 w-8 " />
                        </a>
                    </div>
                </ul>
            </div>
            <hr className="my-6 border-purple-600 sm:mx-auto" />
            <span className="block text-lg text-white sm:text-center">@2022
                <div className="hover:underline"> NFT FABRIC
                </div>
            </span>
        </footer>
    )
}

export default Footer