import React from 'react'

const Launchpad = () => {
    return (
        <aside className="overflow-hidden sm:grid sm:grid-cols-2">
            <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                <div className="max-w-xl mx-auto text-center sm:text-left">
                    <h1 className="text-5xl lg:text-6xl font-bold bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">
                        Create Launchpad
                    </h1>

                    <p className="text-black md:mt-4 block lg:text-4xl font-semibold mt-10 lg:mt-20">
                        Create your own crypto incubator to support the development of Web3 technology among new projects.
                    </p>

                    <div className="mt-4 md:mt-8">
                        <div
                            className="inline-block px-12 py-3 text-xl font-bold text-white transition rounded bg-indigo-500 hover:bg-purple-600 focus:outline-none focus:ring focus:ring-yellow-400"
                        >
                            <a href="/#contact">CONTACT US </a>
                        </div>
                    </div>
                </div>
            </div>

        <div className="mr-4">
            <img
                alt="nft fabric"
                src={require('../../../image/home3.jpg')}
                className="object-cover w-full h-full"
            />
            </div>
        </aside>
    )
}

export default Launchpad