import React from 'react'
import { GiCardExchange } from "react-icons/gi"
import { AiFillBank } from "react-icons/ai"
import { IoMdSwap } from "react-icons/io"
import { HiOutlineCreditCard } from "react-icons/hi"

const Defi = () => {
  return (
    <div className="pb-16" style={{ fontFamily: '"Lato", sans-serif' }} id="defi">
      {/* Code block starts */}
      <dh-component>
        <section className="max-w-8xl mx-auto container bg-white pt-16">
          <div>
            <div role="contentinfo" className="flex items-center flex-col px-4">
              <h1 tabIndex={0} className="focus:outline-none text-5xl lg:text-7xl font-semibold text-center leading-10 bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text lg:w-5/12 md:w-9/12 pt-4">Decentralized Finance</h1>
            </div>
            <div tabIndex={0} aria-label="group of cards" className="focus:outline-none mt-20 flex flex-wrap justify-center gap-10 px-4">
              <div tabIndex={0} aria-label="card 1" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-purple-200 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-black rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <GiCardExchange className="h-10 w-10" />
                  </div>
                </div>
                <div className="w-10/12">
                  <h2 tabIndex={0} className="focus:outline-none text-4xl font-bold leading-tight bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">DEX</h2>
                  <p tabIndex={0} className="focus:outline-none text-2xl text-black font-semibold pt-2">Create decentralized asset exchange platforms</p>
                </div>
              </div>
              <div tabIndex={0} aria-label="card 2" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-purple-200 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-black rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <AiFillBank className="h-10 w-10" />
                  </div>
                </div>
                <div className="w-10/12">
                  <h2 tabIndex={0} className="focus:outline-none text-4xl font-semibold leading-tight bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text uppercase">Liquidity pools</h2>
                  <p tabIndex={0} className="focus:outline-none text-2xl text-black font-semibold pt-2">Build liquidity pools that will fuel economies for the entire ecosystem on which a given cryptocurrency is based</p>
                </div>
              </div>
              <div tabIndex={0} aria-label="card 3" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-purple-200 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-black rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <IoMdSwap className="h-10 w-10" />
                  </div>
                </div>
                <div className="w-10/12">
                  <h2 tabIndex={0} className="focus:outline-none text-4xl font-semibold leading-tight bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">SWAPS</h2>
                  <p tabIndex={0} className="focus:outline-none text-2xl text-black font-semibold pt-2">Create any cryptocurrency pairs</p>
                </div>
              </div>
              <div tabIndex={0} aria-label="card 4" className="focus:outline-none flex sm:w-full md:w-5/12 pb-20">
                <div className="w-20 h-20 relative mr-5">
                  <div className="absolute top-0 right-0 bg-purple-200 rounded w-16 h-16 mt-2 mr-1" />
                  <div className="absolute text-white bottom-0 left-0 bg-black rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <HiOutlineCreditCard className="h-10 w-10" />
                  </div>
                </div>
                <div className="w-10/12">
                  <h2 tabIndex={0} className="focus:outline-none text-4xl font-semibold leading-tight bg-gradient-to-r bg-clip-text text-transparent from-indigo-500 via-purple-500 to-indigo-400 animate-text">LEADING</h2>
                  <p tabIndex={0} className="focus:outline-none text-2xl text-black font-semibold pt-2">Create a leading cryptocurrency resource. Set the amount and interest rate on Your deposits</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </dh-component>
      {/* Code block ends */}
    </div>
  )
}

export default Defi