import React from 'react'
import { AiOutlineArrowLeft } from "react-icons/ai"


const HeaderPortfolio = () => {
    return (
        <div className="top-2 w-screen px-[3em] py-[0.8rem] flex">
            <a href="/">
                <div className="flex item-center cursor-pointer">
                    <AiOutlineArrowLeft size="50" color="#9370DB" />
                </div>
            </a>
        </div>
    )
}

export default HeaderPortfolio