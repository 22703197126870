import React from 'react'
import { dataPortfolio } from './Projects/dataPortoflio'

const PortfolioContent = () => {
  return (
    <div className="mx-auto py-8 px-4 sm:px-6 w-full max-w-7xl">
      <div className="mx-auto max-w-2xl lg:max-w-none">
        
        <div className="mt-6">
          <ul className="grid grid-cols-2 gap-10">
            {dataPortfolio.map(data => (
              <li key={data.id} className="bg-gray-200 col-span-full sm:col-span-2 lg:col-span-1 group shadow-purple-600 shadow-sm rounded border border-purple-100 hover:shadow-purple-600 hover:shadow-lg">
                <a href={data.href} className="p-2 flex flex-col">
                  {/* ::Picture */}
                  <div className="aspect-w-1 aspect-h-1 w-full h-full  overflow-hidden filter group-hover:brightness-150">
                    <img src={data.image} alt="NFT FABRIC" className="w-full h-full object-cover object-center" />
                  </div>
                  <div className="mt-5 pt-4 pb-2 border-t-2 border-gray-100 flex flex-col">
                    <h3 className="text-black font-bold text-3xl">{data.name}</h3>
                    <p className="text-lg text-black mt-6 lg:mt-10">{data.desc}</p>
                    <p className="text-xl text-black font-semibold mt:-4 lg:mt-10 mb-4">Start price: {data.startPrice}</p>
                    <p className="text-xl text-black font-semibold mb-4">Max supply: {data.maxSupply}</p>
                    <p className="text-xl text-black font-semibold">Opensea: {data.opensea}</p>
                  </div>
                </a>
              </li>
            ))
            }
          </ul>
        </div>

      </div>
    </div>
  )
}

export default PortfolioContent