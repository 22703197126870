import React from 'react'
import { AcademicCapIcon, PhotographIcon, CodeIcon, GlobeIcon } from '@heroicons/react/outline'



const Feature = () => {
    return (
        <div className="relative mx-auto py-10 w-full max-w-7xl bg-white text-white" id="features">

            {/* :BACKGROUND IMAGE */}

            {/* :MAIN CONTAINER */}
            <div className="relative w-full flex flex-col lg:flex-row justify-center items-center">

                {/* ::TITLE CONTAINER */}
                <div className="flex-shrink-0 mx-auto mb-10 px-4 text-center lg:text-left">
                    {/* :::Title */}
                    <h2 className="text-4xl xl:text-5xl text-black text-opacity-80 font-rubik tracking-wider space-y-3">
                        <span className="block">Create your <b className="text-black">dreamed up</b></span>
                        <span className="block hover:text-purple-500">Web3 <b className="text-black">Project</b></span>
                    </h2>
                    {/* :::Bottom line */}
                    <span className="mt-5 mx-auto lg:mx-0 w-32 h-1.5 block rounded-3xl bg-black" />
                </div>



                {/* ::FEATURES CONTAINER */}
                <dl className="flex-grow-0 px-4 grid grid-cols-4 gap-4">

                    {/* :::Feature 1 -> Analysis */}
                    <div className="col-span-full sm:col-span-2 md:col-span-1 lg:col-span-2 mx-auto py-5 px-2.5 w-full max-w-xs flex flex-col justify-center items-center space-y-2 rounded-sm border-2 border-indigo-500 bg-black bg-opacity-80 text-center">
                        {/* Icon */}
                        <span className="w-14 h-14 inline-flex justify-center items-center rounded-full bg-gray-100">
                            <AcademicCapIcon className="w-10 h-10 text-black" />
                        </span>
                        {/* Feature name */}
                        <dt className="text-xl lg:text-2xl font-rubik font-bold uppercase tracking-widest text-purple-300">Analysis</dt>
                        {/* Description */}
                        <dd className="py-2 text-lg lg:text-xl text-white">Our team of cryptocurrency analysts can help You create a token economy and smart contract flows.</dd>
                    </div>

                    {/* :::Feature 2 -> Designing */}
                    <div className="col-span-full sm:col-span-2 md:col-span-1 lg:col-span-2 mx-auto py-5 px-2.5 w-full max-w-xs flex flex-col justify-center items-center space-y-2 rounded-sm border-2 border-indigo-500 bg-black bg-opacity-80 text-center">
                        {/* Icon */}
                        <span className="w-14 h-14 inline-flex justify-center items-center rounded-full bg-gray-100">
                            <PhotographIcon className="w-10 h-10 text-black" />
                        </span>
                        {/* Feature name */}
                        <dt className="text-xl lg:text-2xl font-rubik font-bold uppercase tracking-widest text-purple-300">Designing</dt>
                        {/* Description */}
                        <dd className="py-2 text-lg lg:text-xl text-white">We will prepare an analysis of the Web3 project for you. We will present a detailed plan for building the application and a quote for all costs.</dd>
                    </div>

                    {/* :::Feature 3 -> Code */}
                    <div className="col-span-full sm:col-span-2 md:col-span-1 lg:col-span-2 mx-auto py-5 px-2.5 w-full max-w-xs flex flex-col justify-center items-center space-y-2 rounded-sm border-2 border-indigo-500 bg-black bg-opacity-80 text-center">
                        {/* Icon */}
                        <span className="w-14 h-14 inline-flex justify-center items-center rounded-full bg-gray-100">
                            <CodeIcon className="w-10 h-10 text-black" />
                        </span>
                        {/* Feature name */}
                        <dt className="text-xl lg:text-2xl font-rubik font-bold uppercase tracking-widest text-purple-300">Development</dt>
                        {/* Description */}
                        <dd className="py-2 text-lg lg:text-xl text-white">We will create every model of a decentralized application. The creation of Dapps includes both the Smart Contracts code and the web3 frontend</dd>
                    </div>

                    {/* :::Feature 4 -> Production */}
                    <div className="col-span-full sm:col-span-2 md:col-span-1 lg:col-span-2 mx-auto py-5 px-2.5 w-full max-w-xs flex flex-col justify-center items-center space-y-2 rounded-sm border-2 border-indigo-500 bg-black bg-opacity-80 text-center">
                        {/* Icon */}
                        <span className="w-14 h-14 inline-flex justify-center items-center rounded-full bg-gray-100">
                            <GlobeIcon className="w-10 h-10 text-black" />
                        </span>
                        {/* Feature name */}
                        <dt className="text-xl lg:text-2xl font-rubik font-bold uppercase tracking-widest text-purple-300">Production</dt>
                        {/* Description */}
                        <dd className="py-2 text-lg lg:text-xl text-white">We will create for you the entire process of launching an application to the Web3 network, including listings on various exchanges and decentralized exchanges.</dd>
                    </div>

                </dl>


            </div>
        </div>
    )
}

export default Feature