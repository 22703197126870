import React from 'react'
import { MailIcon } from '@heroicons/react/outline'

const Contact = () => {


    return (
        <div className="relative mx-auto w-full max-w-7xl bg-white text-black" id="contact">
            <div className="flex">

                {/* :CONTACT INFOS CONTAINER */}
                <div className="order-2 md:order-3 col-span-full md:col-span-1 py-5 md:py-10 px-6">
                    <div className="mx-auto max-w-xl flex flex-col space-y-5">
                        {/* ::Title Contact Us */}
                        <h2 className="text-5xl lg:text-7xl font-oswald uppercase">Contact us</h2>
                        {/* ::Text */}
                        <p className="text-2xl text-black"><br></br>We are at Your disposal</p>
                        {/* ::Email contact */}
                        <p className="inline-flex items-center text-2xl text-indigo-500 font-semibold hover:text-purple-500">
                            <MailIcon className="mr-2 w-7 text-black" />
                            nftfabric@nftfabric.net
                        </p>
                        {/* ::Address */}
                        <p className="text-xl text-black leading-6">Dolna 2a <br /> 00-774 Warsaw <br /> Poland </p>
                    </div>
                    <div className="h-40">{/* VOID PLACE */}</div>
                </div>
            </div>
        </div>
    )
}
export default Contact